.education-card {
  padding: 9px;
  width: 45%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.education-logo {
  height: 80px;
  margin-left: 10px;
}

.education-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.school-name {
  font-size: 24px;
}

.education-dates {
  color: gray;
}

.education-desc {
  text-align: left;
  max-width: 90%;
}

@media only screen and (max-width: 720px) {
  .education-card {
    width: 100%;
  }
}
