.mobile {
  background-color: rgba(255, 255, 255, 0.9);
  height: 100vh;
  width: 60vw;
  position: absolute;
  right: 0px;
  top: -6px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
}

.mobile-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  justify-content: center;
  align-items: left;
  height: 100%;
}

.mobile-option {
  font-size: 20px;
  margin: 12px 0px;
  display: flex;
}

.mobile-option a {
  text-decoration: none;
  color: black;
  display: flex;
}
