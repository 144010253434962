.about {
  padding-bottom: 48px;
}

.about-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.picture {
  height: 500px;
}

.about-info {
  font-size: 24px;
}

.info-name {
  color: #784feb;
  font-size: 32px;
}

.no-break {
  white-space: nowrap;
}

@media only screen and (max-width: 720px) {
  .about-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .picture {
    height: 280px;
  }
  .about-info {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .info-name {
    font-size: 26px;
  }
}
