.header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 12px;
  position: relative;
  align-items: center;
  margin-top: 10px;
}

.menu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.mobile-menu {
  display: none;
}

.web-menu {
  display: flex;
}

.logo {
  display: flex;
  font-size: 36px;
  font-weight: 800;
  color: blueviolet;
}

@media only screen and (max-width: 720px) {
  .mobile-menu {
    display: flex;
  }
  .web-menu {
    display: none;
  }
  .logo {
    font-size: 24px;
  }
}
