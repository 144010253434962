.project-card {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 16px 0px 75px;
  padding: 8px 0px;
}

.project-photo {
  width: 40%;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
}

.project-info {
  margin-right: 24px;
}

.project-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-tags {
  display: flex;
  max-width: 60%;
  flex-wrap: wrap;
}

.tag {
  padding: 4px;
  background-color: aquamarine;
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: 4px;
}

.project-links a {
  text-decoration: none;
  color: black;
  margin-top: 10px;
}

.project-links {
  margin-top: 6px;
  display: flex;
}

.link-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #784feb;
  color: white;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 6px;
}

.link-button i {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

@media only screen and (max-width: 720px) {
  .project-photo {
    width: 70%;
  }

  .project-card {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .project-info {
    margin-right: 0px;
    margin-top: 12px;
  }
  .project-tags {
    max-width: 100%;
  }
}
