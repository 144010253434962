.web {
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: 10px;
}

.web-option {
  font-size: 20px;
  margin-right: 25px;
  font-weight: 700;
}

.web-option a {
  text-decoration: none;
  color: black;
  display: flex;
  vertical-align: center;
}

.web-option:last-child {
  margin-right: 0px;
}
